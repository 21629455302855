var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"aqz-container working-with-us-pages"},[_c('b-row',[_c('b-col',{staticClass:"background_page",attrs:{"cols":"12","md":"12"}},[_c('div',{staticClass:"contact-us-page"},[_c('div',{staticClass:"customer-care-content__header"},[_c('div',{staticClass:"content-title"},[_c('h1',{staticClass:"title"},[_vm._v(_vm._s(_vm.$t('workWithUs.title')))])])]),_c('b-img',{attrs:{"src":_vm.imageTop,"fluid":"","alt":"Responsive image"}}),_c('div',{staticClass:"formHeaderContent container-fluid"},[_c('div',{staticClass:"header_form"},[_c('div',{staticClass:"header_form_title"},[_c('h2',[_vm._v(_vm._s(_vm.$t('workWithUs.form_title')))])]),_c('div',{staticClass:"header_form_desc"},[_c('p',[_vm._v(_vm._s(_vm.$t('workWithUs.form_desc1'))+"\n                "),_c('br'),_vm._v("\n                "+_vm._s(_vm.$t('workWithUs.form_desc2')))])])]),_c('div',{staticClass:"customer-care-content__body text-left"},[_c('div',{staticClass:"contact-form"},[_c('form',{staticClass:"form-contact",on:{"submit":function($event){$event.preventDefault();return _vm.handleSubmit($event)}}},[_c('div',{staticClass:"mandatory-field"},[_c('span',{staticClass:"t-small"},[_vm._v("*"+_vm._s(_vm.$t('mandatory-field')))])]),_c('b-row',[_c('b-col',{attrs:{"cols":"12","md":"4"}},[_c('b-form-group',{attrs:{"id":"groupFirstname"}},[_c('base-input',{directives:[{name:"validate",rawName:"v-validate",value:('required'),expression:"'required'"}],attrs:{"name":"firstname","type":"text","autofocus":true,"placeholder":'* ' +_vm.$t('address.FirstName'),"validations":[
                            {
                              condition: _vm.errors.has('firstname'),
                              text: _vm.errors.first('firstname')
                            }
                          ]},model:{value:(_vm.form.firstname),callback:function ($$v) {_vm.$set(_vm.form, "firstname", $$v)},expression:"form.firstname"}})],1)],1),_c('b-col',{attrs:{"cols":"12","md":"4"}},[_c('b-form-group',{attrs:{"id":"groupLastName"}},[_c('base-input',{directives:[{name:"validate",rawName:"v-validate",value:('required'),expression:"'required'"}],attrs:{"name":"lastname","type":"text","placeholder":'* ' +_vm.$t('address.LastName'),"validations":[
                            {
                              condition: _vm.errors.has('lastname'),
                              text: _vm.errors.first('lastname')
                            }
                          ]},model:{value:(_vm.form.lastname),callback:function ($$v) {_vm.$set(_vm.form, "lastname", $$v)},expression:"form.lastname"}})],1)],1),_c('b-col',{attrs:{"cols":"12","md":"4"}},[_c('b-form-group',{attrs:{"id":"groupEmail"}},[_c('base-input',{directives:[{name:"validate",rawName:"v-validate",value:('required|email'),expression:"'required|email'"}],attrs:{"name":"email","type":"email","placeholder":'* ' +_vm.$t('address.Email'),"validations":[
                            {
                              condition: _vm.errors.has('email'),
                              text: _vm.errors.first('email')
                            }
                          ]},model:{value:(_vm.form.email),callback:function ($$v) {_vm.$set(_vm.form, "email", $$v)},expression:"form.email"}})],1)],1)],1),_c('b-row',[_c('b-col',{attrs:{"cols":"12","md":"4"}},[_c('b-form-group',{attrs:{"id":"groupAddress"}},[_c('base-input',{directives:[{name:"validate",rawName:"v-validate",value:('required'),expression:"'required'"}],attrs:{"name":"StreetAddress","type":"text","placeholder":'* ' +_vm.$t('address.StreetAddress'),"validations":[
                            {
                              condition: _vm.errors.has('StreetAddress'),
                              text: _vm.errors.first('StreetAddress')
                            }
                          ]},model:{value:(_vm.form.address),callback:function ($$v) {_vm.$set(_vm.form, "address", $$v)},expression:"form.address"}})],1)],1),_c('b-col',{attrs:{"cols":"12","md":"4"}},[_c('b-form-group',{attrs:{"id":"groupCountry"}},[_c('BaseSelect',{directives:[{name:"validate",rawName:"v-validate",value:('required'),expression:"'required'"}],attrs:{"name":"country","placeholder":'* ' +_vm.$t('address.Country'),"options":_vm.optionsCountries,"reduce":function (opt) { return opt.value; },"validations":[
                            {
                              condition: _vm.errors.has('country'),
                              text: _vm.errors.first('country')
                            }
                          ]},model:{value:(_vm.form.country),callback:function ($$v) {_vm.$set(_vm.form, "country", $$v)},expression:"form.country"}})],1)],1),_c('b-col',{attrs:{"cols":"12","md":"4"}},[_c('b-form-group',{attrs:{"id":"groupLastCodiceRiferimento"}},[_c('base-input',{directives:[{name:"validate",rawName:"v-validate",value:('required'),expression:"'required'"}],attrs:{"name":"codiceRiferimento","type":"text","placeholder":'* ' +_vm.$t('address.CodiceRiferimento'),"validations":[
                            {
                              condition: _vm.errors.has('codiceRiferimento'),
                              text: _vm.errors.first('codiceRiferimento')
                            }
                          ]},model:{value:(_vm.form.codiceRiferimento),callback:function ($$v) {_vm.$set(_vm.form, "codiceRiferimento", $$v)},expression:"form.codiceRiferimento"}})],1)],1)],1),_c('b-row',[_c('b-col',{attrs:{"cols":"12","md":"4"}},[_c('b-form-group',{attrs:{"id":"groupLinkedin"}},[_c('base-input',{directives:[{name:"validate",rawName:"v-validate",value:('required'),expression:"'required'"}],attrs:{"name":"linkedin","type":"text","placeholder":'* ' +_vm.$t('address.linkedin'),"validations":[
                            {
                              condition: _vm.errors.has('linkedin'),
                              text: _vm.errors.first('linkedin')
                            }
                          ]},model:{value:(_vm.form.linkedin),callback:function ($$v) {_vm.$set(_vm.form, "linkedin", $$v)},expression:"form.linkedin"}})],1)],1)],1),_c('b-row',[_c('b-col',{attrs:{"cols":"12","md":"8"}},[_c('b-form-group',{attrs:{"id":"groupDoB"}},[_c('input-dob',{directives:[{name:"validate",rawName:"v-validate",value:('required'),expression:"'required'"}],class:{'is-invalid': _vm.errors.has('dob') },attrs:{"id":"dob","name":"dob","validations":[
                            {
                              condition: _vm.errors.has('dob'),
                              text: _vm.errors.first('dob')
                            }
                          ],"required":true},model:{value:(_vm.form.date_of_birth),callback:function ($$v) {_vm.$set(_vm.form, "date_of_birth", $$v)},expression:"form.date_of_birth"}}),_c('b-form-invalid-feedback',{directives:[{name:"show",rawName:"v-show",value:(_vm.errors.has('dob')),expression:"errors.has('dob')"}]},[_vm._v("\n                            "+_vm._s(_vm.errors.first('dob'))+"\n                        ")])],1)],1)],1),_c('b-row',[_c('b-col',[_c('b-form-group',{attrs:{"id":"groupPersonalMessage"}},[_c('BaseTextarea',{directives:[{name:"validate",rawName:"v-validate",value:('required'),expression:"'required'"}],attrs:{"name":"personalmessage","placeholder":'* ' + _vm.$t('customerCare.contact.write_message'),"rows":12,"number":true,"validations":[
                            {
                              condition: _vm.errors.has('personalmessage'),
                              text: _vm.errors.first('personalmessage')
                            }
                          ]},model:{value:(_vm.form.message),callback:function ($$v) {_vm.$set(_vm.form, "message", $$v)},expression:"form.message"}}),_c('p',{staticClass:"cl-gray mt-2 mb-0"},[_vm._v(_vm._s(_vm.totalcount)+" "+_vm._s(_vm.$t('customerCare.contact.charachters_left')))])],1)],1)],1),_c('b-row',{staticClass:"upload-file-row"},[_c('b-col',[_c('b-form-group',{staticClass:"upload-file-label",attrs:{"id":"groupUploadFile"}},[_c('label',{staticClass:"custom-file-upload"},[_c('input',{staticClass:"d-none",attrs:{"type":"file","accept":_vm.acceptFile},on:{"change":function($event){return _vm.processFile($event)}}}),_c('span',{staticClass:"upload-intro f-semibold"},[_c('i',{staticClass:"ff-icon ff-icon-18-plus"}),_vm._v("\n                            "+_vm._s(_vm.$t('customerCare.contact.upload_document'))+"\n                          ")])]),_c('ul',{staticClass:"files-list-text list-group"},_vm._l((_vm.form.uploadfile),function(file,index){return _c('li',{key:file.lastModified,staticClass:"filename-list list-group-item"},[_c('button',{staticClass:"btn btn-none font-weight-bold",on:{"click":function($event){return _vm.removeFile(index)}}},[_c('i',{staticClass:"ff-icon-04-close"})]),_vm._v("\n                            "+_vm._s(file.name)+"\n                          ")])}),0)])],1)],1),_c('b-row',[_c('b-col',{attrs:{"md":"10"}},[_c('div',{staticClass:"form-end-row"},[_c('div',{staticClass:"form-end-intro"},[_c('p',[_vm._v("\n                            "+_vm._s(_vm.$t('customerCare.contact.privacy-info-transmitted'))+"\n                          ")])]),_c('b-form-group',{staticClass:"privacy-check",attrs:{"id":"groupCheckBox"}},[_c('base-check-box',{directives:[{name:"validate",rawName:"v-validate",value:('required:true'),expression:"'required:true'"}],attrs:{"name":"privacy","label":_vm.$t('workWithUs.privacy'),"validations":[
                              {
                                condition: _vm.errors.has('privacy'),
                                text: _vm.errors.first('privacy')
                              }
                            ]},model:{value:(_vm.form.privacyChecked),callback:function ($$v) {_vm.$set(_vm.form, "privacyChecked", $$v)},expression:"form.privacyChecked"}})],1),_c('button',{staticClass:"btn btn-primary btn-primary submit-form",attrs:{"type":"submit"}},[_vm._v(_vm._s(_vm.$t('send')))])],1)])],1)],1)])])])],1)])],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }